import { Suspense, lazy, memo } from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
// cmp
import ImagePlayer from "./ImagePlayer";
// services
import Constants from "../../services/constants";
// types
import type { ReactEventHandler } from "react";
// lazy
const VideoPlayer = lazy(() => import("./VideoPlayer"));

type Props = {
	capabilities: number;
	url: string;
	onLoad: ReactEventHandler<HTMLVideoElement | HTMLImageElement>;
	onError: ReactEventHandler<HTMLVideoElement | HTMLImageElement>;
};

const PlayerChooser = ({ capabilities, url, onLoad, onError }: Props) => {
	// if (capabilities & Constants.StreamingCapabilities.WebRTC) {
	// 	return (
	// 		<WebRTC
	// 			deviceId={props.deviceId}
	// 			config={props.streamingConfig}
	// 			showVideoControls={!props.showPanTiltControls}
	// 			onIceStateComplete={props.handleConnectionCompletion}
	// 			onLoad={onLoad}
	// 			onError={onError}
	// 		/>
	// 	);
	// }

	if (capabilities & Constants.StreamingCapabilities.HLS) {
		return (
			<Suspense fallback={<Skeleton variant="rectangular" height={280} />}>
				<VideoPlayer
					videoJsOptions={{
						autoplay: true,
						controls: false,
						responsive: true,
						sources: [{
							src: url,
							type: "application/vnd.apple.mpegurl",
						}],
					}}
					videoElement={{
						style: { width: "100%", maxHeight: "600px" },
						onLoadedData: onLoad,
						onError: onError,
					}}
				/>
			</Suspense>
		);
	}

	if (capabilities & Constants.StreamingCapabilities.MJPEG) {
		return (
			<ImagePlayer
				url={url}
				onLoad={onLoad}
				onError={onError}
			/>
		);
	}

	console.warn("No player match", capabilities);
	return null;
};

PlayerChooser.propTypes = {
	capabilities: PropTypes.number.isRequired,
	url: PropTypes.string.isRequired,
	onLoad: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired,
};

export default memo(PlayerChooser);
